<template>
    <div class="ticket-main">
        <div class="ticket__ticket-info-wrapper">
            <van-row class="ticket__ticket-logo-wrapper">
                <van-image class="ticket__ticket-logo" width="40" height="40" src="https://ticket-1301949435.cos.ap-nanjing.myqcloud.com/img/public/goods/starbucks.png"/>
            </van-row>
            <!-- <van-row class="ticket__ticket-account-title-wrapper"> -->
                <!-- <span>使用卡券</span> -->
            <!-- </van-row> -->
            <!-- 图片条形码128卡券 -->
            <!-- <van-row type="flex" justify="center" class="ticket_ticket-info-barcode128-wrapper">
                <van-image width="320" height="70" :src="barcode128Pic"/>
            </van-row> -->
            <!-- 图片二维码券码 -->
            <van-row type="flex" justify="center">
                <van-image width="230" height="230" :src="qrPic"/>
            </van-row>
            <!-- 券码字符串 -->
            <van-row class="ticket__ticket-info-str-wrapper">
                <div class="ticket__ticket-info-title">星巴克中杯饮品券</div>
                <div class="ticket__ticket-info-str">
                    {{account}}
                </div>
            </van-row>
            <!-- 质保期 -->
            <!-- <van-row type="flex" justify="center" class="ticket__ticket-guarantee-time"> -->
                <!-- 质保期: {{guaranteeTime}} -->
                <!-- 质保期: 24小时内 -->
            <!-- </van-row> -->
            <!-- 卡券的分隔符 -->
            <van-row>
                <tic-divider/>
            </van-row>
            <van-row class="ticket__ticket-warning-title-wrapper"><span class="ticket__ticket-warning-title">使用条款</span></van-row>
            <van-row class="ticket__ticket-warning-wrapper">
                <ul class="ticket__ticket-warning-content">
                    <li>持券人可凭本券在其有效期内于中国大陆地区指定星巴克门店内（不适用的门店详情见官方说明），兑换一款中杯、标准配方的现场手工调制饮料（特定饮品除外）；<br/>
                    </li>
                    <li>特定饮品指的是预包装饮料（包括但不限于瓶装水、瓶装果汁、瓶装星冰乐）、星巴克臻选咖啡、虹吸壶冲泡咖啡、星巴克气致冷萃系列饮品、星巴克冰淇凌系列产品等；
                        星巴克有权不时地对上述不再兑换范围内的产品做出调整或补充；
                    </li>
                    <li>本券不可转售、不能兑换现金，不找零，不与其他优惠同享。</li>
                </ul>
            </van-row>
        </div>
    </div> 
</template>

<script>
import {NavBar, Image, Row, Col} from "vant";
import TicketStarbucksDivider from "@/components/TicketStarbucksDivider.vue";
import request from "@/utils/request.js";

export default {
    name: "Ticket",
    components: {
        [NavBar.name]: NavBar,
        [Image.name]: Image,
        [Row.name]: Row,
        [Col.name]: Col,
        [TicketStarbucksDivider.name]: TicketStarbucksDivider
    },
    data() {
        return {
            account: undefined,
            guaranteeTime: '72小时内',
            qrPic: undefined,
            barcode128Pic: undefined,
        };
    },
    mounted() {
        this.account = this.$route.params.account;
        this.transformAccount(this.account);
    },
    methods: {
        async transformAccount() {
            const {data} = await request.get(`/order/account2Pic?account=${this.account}`);
            if (data) {
                this.account = data.account;
                this.password = data.password;
                this.qrPic = data.qrPic;
                this.barcode128Pic= data.barcode128;
            } else {
                this.account = "尚未发货";
            }
        }
    }
}
</script>

<style lang="less">
@color-theme: #08a762;
@color-border:#00000026;
@font-size-title: 16px;
@font-weight-title: 500;
@line-height-title: 24px;
@font-color-title: #000000d9;

@font-size-normal: 14px;
@font-weight-normal: 400;
@line-height-normal: 22px;
@font-color-normal: #000000a6;

.ticket-main {
    padding: 100px 16px 16px 16px;
    min-height: 100vh;
    background-color: @color-theme;

    .van-nav-bar {
        background-color: @color-theme;
    }
    .van-nav-bar.van-hairline--bottom::after {
        border: none;
    }

    // 导航栏文字
    .van-nav-bar__text {
        color: white;
    }

    .van-icon.van-nav-bar__arrow {
        color: white;
    }

    .van-nav-bar__title {
        color: white;
    }
}

.ticket__ticket-logo-wrapper {
    text-align: center;
    height: 40px;
}

.ticket__ticket-logo {
    background-color: white;
    padding: 6px;
    border-radius: 999px;
    transform: translate(0, -45%);
    -ms-transform: translate(0, -45%);
}

.ticket__ticket-info-wrapper {
    border-radius: 8px;
    background-color: white;
    margin: 0px 8px 0 8px;
}

.ticket__ticket-account-title-wrapper {
    padding: 16px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
}
.ticket_ticket-info-barcode128-wrapper {
    padding: 16px;
}
.ticket__ticket-info-str-wrapper {
    padding: 16px;
}

.ticket__ticket-info-title {
    text-align: center;
}

.ticket__ticket-info-str {
    // border: 2px solid @color-border;
    // border-radius: 4px;
    text-align: center;
    padding: 8px 8px;
    word-break: break-all;
    color: #aaaaaa;
    font-size: 13px;
}

.ticket__ticket-guarantee-time {
    font-size: @font-size-normal;
    color: @font-color-title;
    line-height: @line-height-normal;
}

.ticket__ticket-warning-title-wrapper {
    text-align: center;
    padding: 0px 16px 16px 16px;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;

    .ticket__ticket-warning-title {
        text-align: center;
        width: 70px;
        display: inline-block;
    }
    .ticket__ticket-warning-title:after {
        content: '';
        width: 70%;
        display: block;
        margin: 0 auto;
        border-bottom: 4px solid black;
    }
}

.ticket__ticket-warning-wrapper {
    padding: 0 32px 48px 32px;
    font-size: @font-size-normal;
    color: @font-color-normal;
    line-height: @line-height-title;
    letter-spacing: 1px;
}

.ticket__ticket-warning-content {
    font-size: 12px;
    line-height: 16px;
    li {
        list-style-type: disc;
        margin-bottom: 6px;
    }
}
</style>